import { createContext, useLayoutEffect, useState } from "react";
import { useGetStyle } from "./API";
import { applyCustomColor } from "./helpers";

export const ThemeContext = createContext({});

export function ThemeProvider({ children }) {
    const { data, error } = useGetStyle();

    useLayoutEffect(() => {
        if (error?.response?.data?.responseCode === 400) window.location.replace("/error");
    }, [error?.response?.data]);

    const theme_mode = localStorage.getItem("theme-mode");
    const direction_mode = localStorage.getItem("direction-mode");

    const [style, setStyle] = useState({
        "theme-mode": theme_mode || "light-mode",
        "direction-mode": direction_mode || "rtl",
        "color-mode": "theme-custom",
        "bgImage-mode": "",
        "menu-mode": "pushcontent",
        "custom-color": "#34445c",
    });

    const [AddOns, setAddOns] = useState([]);
    const [Ads, setAds] = useState([]);
    const [Tenant, setTenant] = useState({});
    const [SocialLinks, setSocialLinks] = useState(false);
    const [PaymentMethods, setPaymentMethods] = useState([]);

    useLayoutEffect(() => {
        if (data?.branch?.group) {
            localStorage.setItem("group", JSON.stringify(data?.branch?.group));
        }
        if (data && data.branch) {
            document.querySelector("html").classList.remove(style["theme-mode"]);
            document.body.classList.remove(style["color-mode"]);
            document.body.classList.remove(style["direction-mode"]);

            const check_default_lang = data.branch.default_lang === "en" ? "ltr" : "rtl";

            setStyle((style) => ({
                ...style,
                "theme-mode": theme_mode || data.branch.theme_mode,
                "direction-mode": direction_mode || check_default_lang,
                "color-mode": data.branch.color_mode,
                "menu-mode": data.branch.menu_mode,
                "bgImage-mode": data.branch.bgImage_mode,
                "custom-color": data.branch.custom_color,
                is_branch_closed: data.branch.is_branch_closed,
                desktop_image: data.branch.group.desktop_image,
                is_overlay_active: data.branch.group.is_overlay_active,
                is_desktop_logo_centered: data.branch.group.is_desktop_logo_centered,
            }));
            localStorage.setItem("theme-mode", theme_mode || data.branch.theme_mode);
            localStorage.setItem("direction-mode", direction_mode || check_default_lang);
        }
        if (data?.addons) {
            setAddOns(Object.assign(...data?.addons.map((item) => ({ [item.name]: item.is_active }))));
        }
        if (data?.payment_methods) {
            setPaymentMethods(data?.payment_methods);
        }
        if (data?.tenant) {
            setTenant(data?.tenant);
        }
        if (data?.["social-links"]) setSocialLinks(data?.["social-links"]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const provider = {
        style,
        AddOns,
        PaymentMethods,
        Tenant,
        SocialLinks,
        loadStyle: () => {
            if (style["color-mode"] === "theme-custom") {
                applyCustomColor(style["custom-color"]);
            }
            const html = document.querySelector("html");
            html.classList.add(style["theme-mode"]);
            document.body.classList.add(style["color-mode"]);
            const color = getComputedStyle(document.getElementsByClassName(style["color-mode"]).item(0)).getPropertyValue("--oneuiux-theme-color");
            document.querySelector("meta[name='theme-color']").setAttribute("content", color);
            document.body.classList.add(style["direction-mode"]);
        },
        styleChange: (temp, value) => {
            if (temp === "theme-mode") document.querySelector("html").classList.remove(style["theme-mode"]);
            if (temp === "color-mode") document.body.classList.remove(style["color-mode"]);
            if (temp === "direction-mode") document.body.classList.remove(style["direction-mode"]);
            setStyle({
                ...style,
                [temp]: value,
            });
            localStorage.setItem(temp, value);
        },
        Ads,
        setAds,
    };
    return <ThemeContext.Provider value={provider}>{children}</ThemeContext.Provider>;
}
